import { wrappedFetch } from 'habitual-analytics/api/services/base/index';
import { parseOrderDetails } from './parser';
import _ from 'lodash';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeader';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { REST_URLS } from 'habitual-analytics/api/resturls';

const getRestEndPoints = () => {
  const { BROKER_PAPER } = REST_URLS();

  return {
    UPDATE_POSITION_BOOK: `${BROKER_PAPER}/positions`,
    GET_TRADE_BOOK: `${BROKER_PAPER}/tradebook`,
    GET_ORDER_BOOK: `${BROKER_PAPER}/orderbook`,
    PLACE_ORDER: `${BROKER_PAPER}/placeorder`,
    GET_POSITION_BOOK: `${BROKER_PAPER}/positions`,
  };
};

const placeOrder = async (orderConfigs) => {
  const parameters = {
    method: 'POST',
    body: JSON.stringify(parseOrderDetails(orderConfigs, 'place_order')),
  };

  return wrappedFetch(getRestEndPoints()?.PLACE_ORDER, parameters).then(
    (response) => response.json()
  );
};

const fetchOrderBook = () => {
  const brokerClientId = getDynamicAppConfigs()?.brokerConfigs?.brokerClientId;

  const apiUrl = `${
    getRestEndPoints()?.GET_ORDER_BOOK
  }?userId=${brokerClientId}`;

  return wrappedFetch(apiUrl)
    .then((response) => response.json())
    .then((result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.orderBook, 'order'),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const brokerClientId = getDynamicAppConfigs()?.brokerConfigs?.brokerClientId;

  const apiUrl = `${
    getRestEndPoints()?.GET_TRADE_BOOK
  }?userId=${brokerClientId}`;

  return wrappedFetch(apiUrl)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.tradeBook, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const brokerClientId = getDynamicAppConfigs()?.brokerConfigs?.brokerClientId;

  const apiUrl = `${
    getRestEndPoints()?.GET_POSITION_BOOK
  }?userId=${brokerClientId}`;

  return wrappedFetch(apiUrl)
    .then((response) => response.json())
    .then((result) => {
      const groupedPositionBook = _.groupBy(
        result?.positionBook,
        'trading_symbol'
      );
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(_.values(groupedPositionBook), 'position', true),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  return {
    holdingBookDetails: [],
    headers: holdingTableHeaders,
  };
};

export {
  fetchOrderBook,
  fetchTradeBook,
  placeOrder,
  fetchPositionBook,
  fetchHoldingBook,
};
