import { getInstrumentsBasedLiveData } from './getInstrumentsBasedLiveData';
import { getInstrumentsBasedHistoricalData } from './getInstrumentBasedHistoricalData';
import { isLiveMode } from 'habitual-analytics/utils/index';

export const getInstrumentBasedLiveOrHistoricalData = async (tradingSymbol) => {
  const data = isLiveMode()
    ? getInstrumentsBasedLiveData(tradingSymbol)
    : getInstrumentsBasedHistoricalData(tradingSymbol);

  return data;
};
