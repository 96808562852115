import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from '../../dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getFormattedMoney } from '../../utils/money';
import { arrayOfInstruments } from 'habitual-analytics/constants/instruments';

import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import Instrument from 'habitual-analytics/models/instrument/index';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import { getDefaultProductCode } from '../tradingSymbolParser';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import { 
  getTradingSymbolsByExchangeTokens 
} from 'habitual-analytics/api/services/getTradingSymbolsByExchangeTokens';

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'LMT';
      break;
    case 'mkt':
      type = 'MKT';
      break;
    case 'sl-m':
      type = 'SLM';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const parseTradingSymbolByExchangeToken = async (exchangeToken) => {
  const exchangeTokens = _.map(exchangeToken, (arr) =>
    _.get(arr, 'security_id', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );

  if (missingTokens.length > 0) {
    const fetchedTokens = await getTradingSymbolsByExchangeTokens(
      missingTokens
    );
    const mergedTokens = _.merge(
      {},
      tradingSymbolsByExchangeTokens,
      fetchedTokens
    );
    initDynamicAppConfigs({
      tradingSymbolsByExchangeTokens: mergedTokens,
    });
  }
};

const getDefaultProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'rl':
      type = 'L';
      break;
    case 'rl-mkt':
      type = 'MKT';
      break;
    case 'sl-mkt':
      type = 'SL-M';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode, tradingSymbolObj) => {
  let productCode;

  switch (_.toLower(pcode)) {
    case 'cnc':
    case 'nrml':
      productCode = tradingSymbolObj.expiryDate ? 'M' : 'C';
      break;
    case 'mis':
      productCode = 'I';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const parseTransactionType = (tnxType) => {
  let transactionType;

  switch (_.toLower(tnxType)) {
    case 'buy':
      transactionType = 'B';
      break;
    case 'sell':
      transactionType = 'S';
      break;
    default:
      transactionType = '';
  }
  return transactionType;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.display_status)) {
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'successful':
      status = statusConfigs.executed.value;
      break;
    case 'adminreject':
    case 'rejected':
    case 'ordererror':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const isNseSegment = (exchangeSegement) => {
  return _.includes(['NSE'], exchangeSegement);
};

const parseSubOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNseSegment(exchange)) {
    return null;
  }

  const productType = _.get(orderDetail, 'display_order_type', '');
  const productCode = _.get(orderDetail, 'display_product', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trigger_price', 0));
  const price = convertToNumber(_.get(orderDetail, 'price', 0));
  const tradedQty = _.get(orderDetail, 'traded_qty', 0);
  const quantity = Number(_.get(orderDetail, 'quantity', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'avg_traded_price', 0));
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;
  const tradedTime = _.get(orderDetail, 'order_date_time', '').split(' ')?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'reason_description', '');
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj: tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'txn_type', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'order_no', ''),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
      defaultProductType: getDefaultProductType(productType),
      order: orderDetail,
    },
  };
};

const parseOrderBook = async (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  await parseTradingSymbolByExchangeToken(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubOrderBook);
  }

  return parseSubOrderBook(orderDetail);
};

const parseTradeBook = async (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNseSegment(exchange)) {
    return null;
  }

  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product_type', '');
  const tradedQuantity = Number(_.get(orderDetail, 'trade_quantity', 0));
  const quantity = Number(_.get(orderDetail, 'trade_quantity', 0));
  const status = statusConfigs.executed.label;
  const tradingSymbolObj = await parseOrderDetailToGetTradingSymbolObj(
    orderDetail
  );

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(
      _.get(orderDetail, 'trade_timestamp', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'SELL'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'trade_price', 0)),
    },
  };
};

const parseSubPositionBook = async (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const dailyOrExpiry = _.get(orderDetail, 'daily_or_expiry', '');

  if (dailyOrExpiry === 'DAILY') {
    return null;
  }
  if (!isNseSegment(exchange)) {
    return null;
  }

  const ltp = convertToNumber(_.get(orderDetail, 'last_traded_price'));
  const qty = Number(_.get(orderDetail, 'net_qty', 0));
  const netAvgPrice = convertToNumber(_.get(orderDetail, 'net_avg', 0));
  const buyAvg = convertToNumber(_.get(orderDetail, 'buy_avg', 0));
  const sellAvg = convertToNumber(_.get(orderDetail, 'sell_avg', 0));
  const realisedprofitloss = _.round(
    _.get(orderDetail, 'realised_profit', 0),
    2
  );
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - netAvgPrice : netAvgPrice - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty);

  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'display_product', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_traded_price',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'display_product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = async (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  await parseTradingSymbolByExchangeToken(orderDetail);

  if (isArrayDetails) {
    return await Promise.all(_.map(orderDetail, parseSubPositionBook));
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'nse_symbol', '');
  const quantity = _.get(orderDetail, 'remaining_quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'cost_price', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'last_traded_price', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const tradingSymbolObj = new Instrument({ symbol: tradingSymbol });

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_price',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  const parsedOrder = {
    txn_type: parseTransactionType(_.get(orderConfig, 'transactionType', '')),
    exchange: 'NSE',
    segment: tradingSymbolObj?.expiryDate ? 'D' : 'E',
    product: parseProductCode(
      _.get(orderConfig, 'pCode', ''),
      tradingSymbolObj
    ),
    security_id: symbolId,
    quantity: _.get(orderConfig, 'qty', ''),
    validity: _.get(orderConfig, 'ret', ''),
    order_type: parseProductType(_.get(orderConfig, 'prctyp', '')),
    price: _.get(orderConfig, 'price', ''),
    source: 'W',
    off_mkt_flag: false,
  };

  if (orderConfig.trigPrice) {
    parsedOrder.trigger_price = _.get(orderConfig, 'trigPrice', '');
  }

  return parsedOrder;
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseCancelOrder = ({ orderDetails }) => {
  const {
    quantity,
    txn_type: tnxType,
    security_id: securityId,
    product,
    serial_no: serialNo,
    group_id: groupId,
    order_type: orderType,
    price,
    trigger_price: triggerPrice,
  } = orderDetails.extraDetails.order;
  return {
    exchange: 'NSE',
    source: 'W',
    txn_type: tnxType,
    segment: orderDetails?.tradingSymbolObj?.isEquity() ? 'E' : 'D',
    security_id: securityId,
    order_no: orderDetails.extraDetails?.orderNo,
    off_mkt_flag: 'false',
    order_type: orderType,
    price,
    trigger_price: triggerPrice,
    mkt_type: 'NL',
    quantity,
    product,
    validity: 'DAY',
    serial_no: _.parseInt(serialNo),
    group_id: `${groupId}`,
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseCancelOrder,
};
