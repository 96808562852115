import moment from 'moment';
import _ from 'lodash';
import { getFixedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from '../../dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from '../../constants/habitual-configs';
import { arrayOfInstruments } from 'habitual-analytics/constants/instruments';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  isNse,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
} from '../tradingSymbolParser';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import { 
  getTradingSymbolsByExchangeTokens 
} from 'habitual-analytics/api/services/getTradingSymbolsByExchangeTokens';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import dayjs from 'dayjs';

const brokerParseTradingSymbol = (tradingSymbolObj) => {
  if (tradingSymbolObj.isOption()) {
    return `${tradingSymbolObj.symbol}${moment(
      tradingSymbolObj.expiryDate
    ).format('DDMMMYY')}${tradingSymbolObj.strikePrice}${
      tradingSymbolObj.optionType === 'CE' ? 'CE' : 'PE'
    }`.toUpperCase();
  } else if (tradingSymbolObj.isFuture()) {
    return `${tradingSymbolObj.symbol}${moment(
      tradingSymbolObj.expiryDate
    ).format('DDMMMYY')}FUT`.toUpperCase();
  } else {
    return `${tradingSymbolObj.getSymbol()}-EQ`;
  }
};

const isNseSegement = (exchange) => {
  return exchange === 'NSE' || exchange === 'NFO';
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.orderDisplayStatus)) {
    case 'cancelled':
    case 'Cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'complete':
    case 'Complete':
      status = statusConfigs.executed.value;
      break;
    case 'executed':
    case 'Executed':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
    case 'Rejected':
      status = statusConfigs.failed.value;
      break;
    case 'pending':
    case 'Pending':
      status = statusConfigs.pending.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseTradingSymbolByExchangeToken = async (exchangeToken) => {
  const exchangeTokens = _.map(exchangeToken, (arr) =>
    _.get(arr, 'scripToken', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );
  if (missingTokens.length > 0) {
    const fetchedTokens = await getTradingSymbolsByExchangeTokens(
      missingTokens
    );
    const mergedTokens = _.merge(
      {},
      tradingSymbolsByExchangeTokens,
      fetchedTokens
    );
    initDynamicAppConfigs({
      tradingSymbolsByExchangeTokens: mergedTokens,
    });
  }
};

const parsePlaceOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = brokerParseTradingSymbol(tradingSymbolObj);
  const exchangeSegement = tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO';
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);
  const actionType =
    _.get(orderConfig, 'transactionType', '').toString() == 'buy' ? 'B' : 'S';

  return {
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
    cuserid: brokerClientId,
    tradingSymbol: formattedTradingSymbol,
    exchangeName: exchangeSegement,
    scripToken: symbolId,
    actionType: actionType,
    validity: _.get(orderConfig, 'ret', '').toString(),
    quantity: _.get(orderConfig, 'qty', '').toString(),
    disclosedQuantity: _.get(orderConfig, 'disCloseQty', '').toString(),
    price: _.get(orderConfig, 'price', '').toString(),
    triggerPrice: _.get(orderConfig, 'trigPrice', '').toString(),
    stoplossTriggerPrice: null,
    orderType: _.get(orderConfig, 'prctyp', '').toString(),
    productType: _.get(orderConfig, 'pCode', '').toString(),
    advanceOrderType: 'RGLR',
    dp: null,
    stopLossPrice: null,
    targetPrice: null,
    trailingStopLoss: null,
    isModifyOrder: false,
  };
};

const parseCancelOrder = (orderConfig, brokerClientId) => {
  const { orderDetails } = orderConfig;

  return {
    cuserid: brokerClientId,
    nestOrderNumber: _.get(orderConfig, 'orderNo', ''),
    exchangeName: _.get(
      orderDetails,
      'extraDetails.orderDetails.exchangeName',
      ''
    ).toString(),
    scripName: _.get(
      orderDetails,
      'extraDetails.orderDetails.scripName',
      ''
    ).toString(),
    orderStatus: _.get(
      orderDetails,
      'extraDetails.orderDetails.orderStatus',
      ''
    ).toString(),
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
  };
};

const parseModifyOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const exchangeSegement = tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO';
  const actionType =
    _.get(orderConfig, 'transactionType', '').toString() == 'buy' ? 'B' : 'S';
  const orderType = _.get(orderConfig, 'orderType', '').toString();

  const parseOptions = {
    cuserid: brokerClientId,
    exchangeName: exchangeSegement,
    scripName: _.get(orderConfig, 'scripName', ''),
    nestOrderNumber: _.get(orderConfig, 'nestOrderNumber', ''),
    actionType: actionType,
    orderType: orderType,
    price: _.get(orderConfig, 'price', ''),
    quantity: _.get(orderConfig, 'qty', '').toString(),
    validity: _.get(orderConfig, 'ret', '').toString(),
    orderStatus: _.get(orderConfig, 'orderStatus', ''),
    filledQuantity: _.get(orderConfig, 'filledQuantity', ''),
    productType: _.get(orderConfig, 'pCode', '').toString(),
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
    triggerPrice: _.get(orderConfig, 'trigPrice', '').toString(),
    disclosedQuantity: _.get(orderConfig, 'disCloseQty', '').toString(),
  };

  if (_.includes(['SL-LMT', 'SL-MKT'], orderType)) {
    parseOptions.triggerPrice = getFixedData(
      _.get(orderConfig, 'trigPrice', '')
    ).toString();
  }

  return parseOptions;
};

const parseMarginCalculator = async (orderConfig, brokerClientId) => {
  const transactionType =
    _.get(orderConfig, 'transactionType', '') === 'buy' ? 'B' : 'S';
  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', {});
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    cuserid: brokerClientId,
    brokerName: 'INDIABULLS',
    exchangeName: _.get(orderConfig, 'exch', ''),
    exchangeSegmentName: _.get(orderConfig, 'exch', '').toLowerCase(),
    scripToken: symbolId,
    productType: _.get(orderConfig, 'pCode', ''),
    branchId: 'ESHUBH ',
    price: _.get(orderConfig, 'price', ''),
    quantity: _.get(orderConfig, 'qty', ''),
    actionType: transactionType,
    orderType: _.get(orderConfig, 'prctyp', ''),
  };
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchangeName', '');
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  if (
    !isNseSegement(exchange) ||
    !_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const status = sanitizeAndParseOrderStatus(orderDetail);
  const tradedTime = _.split(
    _.get(orderDetail, 'exchangeDateTime', ''),
    ' '
  )[1];
  const failedReason = _.get(orderDetail, 'rejectionReason', '');
  const productType = _.get(orderDetail, 'orderType', '');
  const productCode = _.get(orderDetail, 'productCode', '');
  //TODO: Need to check if this is correct
  // const productCode = parseProductCode(_.get(orderDetail, "productCode", ""));

  const isCancellableOrder = getCancellableOrderStatus(status);
  const quantity = _.get(orderDetail, 'quantity', 0);
  const tradedQty = _.get(orderDetail, 'filledQuantity', 0);
  const avgPrice = convertToNumber(_.get(orderDetail, 'averagePrice', 0));
  const price = convertToNumber(_.get(orderDetail, 'price', 0));
  const targetPrice = _.get(orderDetail, 'triggerPrice', 0);
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice.toFixed(2)
        : price.toFixed(2);

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'actionType', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      orderDetails: orderDetail,
      product: `${productCode} / ${productType}`,
      qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'nestOrderNumber', ''),
      exchOrderID: _.toNumber(_.get(orderDetail, 'exchangeOrderId', '')),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchangeName', '');
  if (!isNse(exchange)) {
    return null;
  }
  const ltp = _.get(orderDetail, 'ltp', 0);
  const qty = Number(_.get(orderDetail, 'openQty', 0));
  const buyAvg = Number(_.get(orderDetail, 'buyAvgPrice', 0).replace(/,/g, ''));
  const sellAvg = Number(
    _.get(orderDetail, 'sellAvgPrice', 0).replace(/,/g, '')
  );
  const realisedprofitloss = _.round(_.get(orderDetail, 'realisedMtm', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - Number(buyAvg) : sellAvg - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'productCode', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'productCode', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }
  const data = parseSubPositionBook(orderDetail);
  return data;
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'echangeName', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tsym', '');
  const productType = _.get(orderDetail, 'prctyp', '');
  const productCode = _.get(orderDetail, 's_prdt_ali', '');
  const tradedQuantity = Number(_.get(orderDetail, 'flqty', 0));
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(
      _.get(orderDetail, 'fltm', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'trantype', '') === 'S'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'flprc', 0)),
    },
  };
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch_tsym[0][exch]', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'exch_tsym[0][tsym]', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);
  const quantity = _.get(orderDetail, 'holdqty', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'upldprc', 0));
  const todayOpen = _.get(orderDetail, 'todayOpen', 0);
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const todayProfitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(todayOpen, ',', '')) * quantity
  );
  const todayNetChg = getRoundedData((todayProfitLoss / todayOpen) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      todayNetChg: `${getFormattedMoney(todayNetChg)}%`,
      todayProfitLoss,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'LTnse',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parseOrderDetails = async (orders, type) => {
  await parseTradingSymbolByExchangeToken(orders);

  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parsePlaceOrder,
  parseCancelOrder,
  parseModifyOrder,
  parseMarginCalculator,
  parseOrderBook,
  parsePositionBook,
  parseTradeBook,
  // parseHoldingsBook,
  parseOrderDetails,
};
