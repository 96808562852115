import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { getDeserializedLegs } from 'habitual-analytics/formProvider/BasketFormProvider/processor';
import { getAnalyticsUrlParams } from 'globals/utils/url';
import reducer from './reducer';
import initialState from './initialState';

const urlParams = getAnalyticsUrlParams(false);

const { instrument, expiryDate } = initialState?.headerConfigs || {};
const { legs, currentStrategy } = initialState?.basketConfigs || {};

const updatedLegs = _.get(urlParams, 'legs', legs);
const updatedInstrument = _.get(urlParams, 'instrument', instrument);
const updatedExpiryDate = _.get(urlParams, 'expiryDate', expiryDate);

const updatedInitialState = {
  ...initialState,
  headerConfigs: {
    ...initialState?.headerConfigs,
    instrument: updatedInstrument,
    expiryDate: updatedExpiryDate,
  },
  basketConfigs: {
    ...initialState?.basketConfigs,
    currentStrategy: _.get(urlParams, 'currentStrategy', currentStrategy),
    legs: getDeserializedLegs(updatedLegs, updatedInstrument),
  }
};

const store = createStore(reducer, updatedInitialState, applyMiddleware(thunk));

export default store;
