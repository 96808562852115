import { getDynamicAppConfigs } from './dynamicAppConfigs';
import { instruments } from 'zeequant-constants';
import { avoidedInstruments } from './habitual-configs';
import _ from 'lodash';

export const updatedIndexSymbols = () => {
  const { isBseEnabled, bseEnabledInstruments } = getDynamicAppConfigs()?.domainConfigs;
  
  if (isBseEnabled) {
    return instruments?.indexSymbols;
  }

  return _.filter(instruments.indexSymbols, (symbol) => !_.includes(bseEnabledInstruments, symbol));
};

export const arrayOfInstruments = () => _.compact(
  _.map([...updatedIndexSymbols(), ...instruments.symbols],
    (item) => (!_.includes(avoidedInstruments, item)
      ? item
      : null))
);