import _ from 'lodash';

export const getFormattedMoney = (
  money = 0,
  shouldAvoidDecimals = false,
  isAttachCurrency = false,
  currencyFormat = 'INR',
  isCompact = true,
  decimalPoints = 2
) => {
  let formatedMoney = '';
  // const decimals = Number(money) - Math.floor(money);
  // minimumFractionDigits: !(shouldAvoidDecimals) && decimals ? decimalPoints : 0,
  // maximumFractionDigits: !shouldAvoidDecimals && decimals ? decimalPoints : 0,

  const formattedMoneyOptions = {
    minimumFractionDigits: decimalPoints,
    maximumFractionDigits: decimalPoints,
    ...(isCompact ? { notation: 'compact' } : {}),
    ...(isAttachCurrency
      ? { style: 'currency', currency: currencyFormat }
      : {}),
  };

  try {
    formatedMoney = new Intl.NumberFormat(
      'en-IN',
      formattedMoneyOptions
    )?.format(money);
    formatedMoney = _.replace(formatedMoney, 'T', 'K');
  } catch (error) {
    // case - If the Intl not load, in any browser or slow load.. catch will return the money
    // to avoid errors
    console.error(error);
    formatedMoney = money;
  }
  return formatedMoney;
};
