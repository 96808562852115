import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  DATE_FORMAT,
  EXPIRY_DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import Instrument from 'habitual-analytics/models/instrument/index';
import moment from 'moment';
import _ from 'lodash';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const fetchedTokens = _.find(
    tradingSymbolsByExchangeTokens,
    (value, key) => key == orderDetail?.scripToken
  );

  if (orderDetail?.scripType === 'equity') {
    return new Instrument({
      symbol: fetchedTokens,
    });
  } else {
    const symbol = _.split(_.get(orderDetail, 'displayScripName', []), ' ')[0];
    const date = _.get(orderDetail, 'expiryDate', '');
    const strike = _.split(_.get(orderDetail, 'displayScripName', []), ' ')[1];
    const optionType = _.split(
      _.get(orderDetail, 'displayScripName', []),
      ' '
    )[2];

    return new Instrument({
      symbol: symbol,
      expiryDate: date
        ? moment(date, EXPIRY_DATE_FORMAT).format(DATE_FORMAT)
        : '',
      strikePrice: Number(strike) && strike,
      optionType: optionType === 'FUT' ? undefined : optionType,
    });
  }
};
