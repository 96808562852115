import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseModifyOrder,
  parseOrderBook,
  parseOrderDetails,
  parsePlaceOrder,
  parsePositionBook,
  parseTradeBook,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';

  return `${baseUrl}?broker=investright&method=${method}${queryString}${pathString}`;
};

const isValidAccessToken = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { INVESTRIGHT_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };

  return wrappedFetch(
    getRequestUri('validateUser', `api_key=${INVESTRIGHT_APP_ID}`),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.status === 'success')
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = async () => {
  const { brokerConfigs, envs } = getDynamicAppConfigs();
  const { brokerClientAccessToken } = brokerConfigs;
  const { INVESTRIGHT_APP_ID } = envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };
  return wrappedFetch(
    getRequestUri('orderBook', `api_key=${INVESTRIGHT_APP_ID}`),
    parameters
  )
    .then((response) => response.json())
    .then((result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(parseOrderBook(result?.data), ['time'], ['desc'])
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const { brokerConfigs, envs } = getDynamicAppConfigs();
  const { brokerClientAccessToken } = brokerConfigs;
  const { INVESTRIGHT_APP_ID } = envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };

  return wrappedFetch(
    getRequestUri('tradeBook', `api_key=${INVESTRIGHT_APP_ID}`),
    parameters
  )
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseTradeBook(result?.data, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const { brokerConfigs, envs } = getDynamicAppConfigs();
  const { brokerClientAccessToken } = brokerConfigs;
  const { INVESTRIGHT_APP_ID } = envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };

  return wrappedFetch(
    getRequestUri('positionBook', `api_key=${INVESTRIGHT_APP_ID}`),
    parameters
  )
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parsePositionBook(result?.data?.net, 'position'),
            ['extraDetails.isOpenPosition', 'symbol'],
            ['desc', 'asc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { INVESTRIGHT_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };

  return wrappedFetch(
    getRequestUri('holdings', `api_key=${INVESTRIGHT_APP_ID}`),
    parameters
  )
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(result?.data, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { INVESTRIGHT_APP_ID } = getDynamicAppConfigs().envs;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);

      const parameters = {
        method: 'POST',
        headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
        body: JSON.stringify(orderDetails),
      };

      return wrappedFetch(
        getRequestUri('placeOrder', `api_key=${INVESTRIGHT_APP_ID}`),
        parameters
      )
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ data, status }) => (status === 'success' ? data?.order_id : null))
    .filter((value) => value !== null)
    .value();
};

const cancelPlaceOrder = ({ orderNo }) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { INVESTRIGHT_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };

  return wrappedFetch(
    getRequestUri('cancelPlaceOrder', `api_key=${INVESTRIGHT_APP_ID}`, orderNo),
    parameters
  ).then((response) => response.json());
};

const modifyPlaceOrder = (placeOrderConfigs) => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { INVESTRIGHT_APP_ID } = getDynamicAppConfigs().envs;
  const orderConfig = placeOrderConfigs?.[0];

  const orderDetails = parseModifyOrder(orderConfig);
  const orderNo = _.get(orderConfig, 'orderNo', '');

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
    body: JSON.stringify(orderDetails),
  };

  return wrappedFetch(
    getRequestUri('modifyOrder', `api_key=${INVESTRIGHT_APP_ID}`, `${orderNo}`),
    parameters
  )
    .then((response) => response.json())
    .then(({ status, data }) =>
      status === 'success' ? [data?.order_id] : null
    );
};

const fetchMarginCalculator = async () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  const { INVESTRIGHT_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'ruby',
    },
  };
  const formattedResponse = await wrappedFetch(
    getRequestUri('marginCalculator', `api_key=${INVESTRIGHT_APP_ID}`),
    parameters
  )
    .then((response) => response.json())
    .then(({ data }) => data?.equity)
    .catch((e) => [e.message]);

  const totalMarginRequired = 0;

  const totalMarginAvailable =
    _.parseInt(_.get(formattedResponse, 'total_available_limit', 0)) || 0;

  return { totalMarginRequired, totalMarginAvailable };
};

const fetchMarginAvailable = async () => await fetchMarginCalculator();

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
  modifyPlaceOrder,
  fetchMarginCalculator,
  fetchMarginAvailable,
};
