import { 
  getTradingSymbolsByExchangeTokens 
} from 'habitual-analytics/api/services/getTradingSymbolsByExchangeTokens';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import { getTradingSymbolObj } from '../utils';

export const parseTradingSymbolObj = (orderDetail) => {
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const fetchedTokens = _.findKey(
    tradingSymbolsByExchangeTokens,
    (value, key) => key === orderDetail?.token || key === orderDetail?.nse_token
  );

  return getTradingSymbolObj(tradingSymbolsByExchangeTokens[fetchedTokens]);
};

export const parseTradingSymbolByExchangeToken = async (exchangeToken) => {
  const exchangeTokens = _.map(
    exchangeToken,
    (arr) => _.get(arr, 'token', '') || _.get(arr, 'nse_token', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );

  if (missingTokens.length > 0) {
    const fetchedTokens = await getTradingSymbolsByExchangeTokens(
      missingTokens
    );
    const mergedTokens = _.merge(
      {},
      tradingSymbolsByExchangeTokens,
      fetchedTokens
    );
    initDynamicAppConfigs({
      tradingSymbolsByExchangeTokens: mergedTokens,
    });
  }
};
