import React, { useEffect, useState } from 'react';
import { initDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';

const {
  ANALYTICS_TICKER_PATH,
  ANALYTICS_TICKER_URI,
  ANALYTICS_TICKER_AUTH,
  CORE_SERVICE_URI,
  ALICEBLUE_API_KEY,
  SENTRY_DSN,
  ANGEL_BROKING_API_KEY,
  GOODWILL_API_KEY,
  FYERS_APP_ID,
  NUVAMA_API_KEY,
  NUVAMA_APP_ID,
  NUVAMA_SRC_TOKEN,
  PRABHUDAS_API_KEY,
  RELIGARE_API_KEY,
  AXIS_DIRECT_API_CLIENT_ID,
  AXIS_DIRECT_AUTHORIZATION_KEY,
  SMC_GLOBAL_API_KEY,
  STOX_KART_API_KEY,
  HDFCSKY_APP_ID,
  INVESTRIGHT_APP_ID,
  FIVEPAISA_API_USER_KEY,
  IS_DEV_MODE,
  NUVAMA_VENDOR_LOGIN_URL,
  NUVAMA_LOGIN_URL,
  NUVAMA_API_URL,
  DHANI_STOCKS_APP_KEY,
  DHANI_STOCKS_APP_ID
} = window?.instaConstants || {};

const withEnvs = (WrappedComponent) => {
  return function fromWithEnvs() {
    const [isLoadingEnvs, setIsLoadingEnvs] = useState(true);

    useEffect(() => {
      const envs = {
        ANALYTICS_TICKER_PATH,
        ANALYTICS_TICKER_URI,
        ANALYTICS_TICKER_AUTH,
        CORE_SERVICE_URI,
        BASE_URI: window.location.origin,
        ALICEBLUE_API_KEY,
        SENTRY_DSN,
        ANGEL_BROKING_API_KEY,
        GOODWILL_API_KEY,
        FYERS_APP_ID,
        NUVAMA_API_KEY,
        NUVAMA_APP_ID,
        NUVAMA_SRC_TOKEN,
        PRABHUDAS_API_KEY,
        RELIGARE_API_KEY,
        AXIS_DIRECT_API_CLIENT_ID,
        AXIS_DIRECT_AUTHORIZATION_KEY,
        SMC_GLOBAL_API_KEY,
        STOX_KART_API_KEY,
        HDFCSKY_APP_ID,
        INVESTRIGHT_APP_ID,
        FIVEPAISA_API_USER_KEY,
        IS_DEV_MODE,
        NUVAMA_LOGIN_URL,
        NUVAMA_VENDOR_LOGIN_URL,
        NUVAMA_API_URL,
        DHANI_STOCKS_APP_KEY,
        DHANI_STOCKS_APP_ID
      };
      initDynamicAppConfigs({ envs });
      setIsLoadingEnvs(false);
    }, []);

    if (isLoadingEnvs) {
      return null;
    }

    return <WrappedComponent />;
  };
};

export default withEnvs;
