
const RestEndPoints = {
  GET_HOLDINGS: 'holdings/v1/rmsholdings',
  UPDATE_POSITION_BOOK: 'positions/net',
  GET_TRADE_BOOK: 'tradebook',
  GET_ORDER_BOOK: 'order/book',
  PLACE_ORDER: 'trade/placetrade',
  PROFILE_PATH: 'login/user/vendordetails',
  CANCEL_PLACE_ORDER: 'trade/canceltrade',
  MARGIN_CALCULATOR: 'limits/checkmargin',
};

export default RestEndPoints;
