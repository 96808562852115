import { instrumentLotSizeAndStrikeIntervalConfigs } from 'habitual-analytics/constants/habitual-configs';
import _ from 'lodash';
import {
  getCurrentWeeklyExpiryDate,
  getUpComingExpiryDates,
} from 'habitual-analytics/dateUtils/expiryDateCalculations';
import { instruments } from 'zeequant-constants';
import moment from 'moment';
import { DATE_FORMAT, EXPIRY_DATE_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';

export const getAtmBasedonPremiumPrice = (instrument, premiumPrice) => {
  const currentInstrumentStepSize = _.get(
    instrumentLotSizeAndStrikeIntervalConfigs(instrument, getCurrentWeeklyExpiryDate(instrument)),
    'strike_interval',
    0
  );

  return {
    atm: Math.round(premiumPrice / currentInstrumentStepSize) * currentInstrumentStepSize,
    stepSize: currentInstrumentStepSize,
  };
};

export const getValidatedInstrumentAndExpiryType = (instrument, expiryDate = '') => {
  const constantReturn = { instrument, expiryDate };
  if (_.includes(instruments.indexSymbols, instrument)) {
    constantReturn.expiryDate = getCurrentWeeklyExpiryDate(instrument);
  } else {
    const formattedExpityDate = _.first(getUpComingExpiryDates('M', instrument));
    constantReturn.expiryDate = formattedExpityDate;
  }

  return constantReturn;
};

export const generateLiveDataFormatSymbols = (strikePrices, instrument, expiryDate) => {
  const formattedExpiryDate = moment(expiryDate, DATE_FORMAT)
    .format(EXPIRY_DATE_FORMAT)
    .toUpperCase();
  const monthlyExpiryDate = moment(getUpComingExpiryDates('M', instrument), DATE_FORMAT)
    .format(EXPIRY_DATE_FORMAT)
    .toUpperCase();
  const futureInstrument = `${instrument}${monthlyExpiryDate}FUT`;

  const formattedOptionsInstrumentsList = strikePrices.map((strikePrice) => [
    `${instrument}${formattedExpiryDate}${strikePrice}CE`,
    `${instrument}${formattedExpiryDate}${strikePrice}PE`,
  ]);

  return [instrument, ...formattedOptionsInstrumentsList.flat(), futureInstrument];
};
