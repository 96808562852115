import _ from 'lodash';
import { holidayDates, weekendWorkingDays } from 'zeequant-constants';
import { ANALYTICS_MARKET_DATE_FORMAT, DATE_FORMAT } from './dateFormats';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';

const DAYS_OF_WEEKS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getValidMarketDate = (paramDate) => {
  const recursiveToGetValidDate = (actualDate) => {
    const actualMomentDate = getParsedDateObj(actualDate);
    const day = actualMomentDate.day();
    const dayName = DAYS_OF_WEEKS[day];

    if (
      _.includes(holidayDates, actualDate) ||
      (_.includes(['Saturday', 'Sunday'], dayName) &&
        !_.includes(weekendWorkingDays, actualDate))
    ) {
      return recursiveToGetValidDate(
        actualMomentDate.subtract(1, 'days').format(DATE_FORMAT)
      );
    }

    return actualDate;
  };

  return recursiveToGetValidDate(paramDate);
};

const getCurrentPrevDayMoment = () => {
  let validCurrentDayMoment;
  const currentDateMoment = getCurrentDate();
  const isCurrentDayHoliday =
    getValidMarketDate(currentDateMoment.clone().format(DATE_FORMAT)) !==
    currentDateMoment.format(DATE_FORMAT);

  if (isCurrentDayHoliday) {
    validCurrentDayMoment = getParsedDateObj(
      getValidMarketDate(currentDateMoment.subtract(1, 'day'))
    )
      .set('hour', 15)
      .set('minute', 29);
  } else {
    const currentTime = getCurrentDate();
    const isBeforMarket = currentTime.isBefore(
      getCurrentDate().set('hour', 9).set('minute', 16).set('seconds', 3)
    );
    const isAfterMarket = currentTime.isAfter(
      getCurrentDate().set('hour', 15).set('minute', 29).set('seconds', 59)
    );

    const previousDayDate = currentDateMoment
      .clone()
      .subtract(1, 'day')
      .format(DATE_FORMAT);

    validCurrentDayMoment = isBeforMarket
      ? getParsedDateObj(getValidMarketDate(previousDayDate))
        .set('hour', 15)
        .set('minute', 29)
      : isAfterMarket
        ? getCurrentDate().set('hour', 15).set('minute', 29)
        : getCurrentDate().subtract(1, 'minute');
  }

  const validPreviousDay = getParsedDateObj(
    getValidMarketDate(
      validCurrentDayMoment.clone().subtract(1, 'day').format(DATE_FORMAT)
    )
  )
    .set('hour', 15)
    .set('minute', 29)
    .format(ANALYTICS_MARKET_DATE_FORMAT);

  return {
    validCurrentDay: getParsedDateObj(validCurrentDayMoment).format(
      ANALYTICS_MARKET_DATE_FORMAT
    ),
    validPreviousDay,
  };
};

export const getMarketTimingConfigs = () => {
  const { validCurrentDay, validPreviousDay } = getCurrentPrevDayMoment();

  return {
    marketStartTime: getParsedDateObj(validCurrentDay)
      .set('hour', 9)
      .set('minute', 15)
      .format(ANALYTICS_MARKET_DATE_FORMAT),

    marketEndTime: getParsedDateObj(validCurrentDay)
      .set('hour', 15)
      .set('minute', 29)
      .format(ANALYTICS_MARKET_DATE_FORMAT),

    marketCurrentTime: validCurrentDay,
    marketPrevDayEndTime: validPreviousDay,
    firstOiStartTime: getParsedDateObj(validCurrentDay)
      .set('hour', 9)
      .set('minute', 18)
      .format(ANALYTICS_MARKET_DATE_FORMAT),
    endOfTicksTablePrevDayEndTime: getParsedDateObj(
      getValidMarketDate(
        getParsedDateObj(validCurrentDay)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      )
    )
      .set('hour', 15)
      .set('minute', 29)
      .format(ANALYTICS_MARKET_DATE_FORMAT),
  };
};
