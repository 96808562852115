 
import { transactionTypes, optionTypes } from 'habitual-analytics/constants/habitual-configs';
import { getCurrentWeeklyExpiryDate } from 'habitual-analytics/dateUtils/expiryDateCalculations';

export const defaultLegConfig = {
  optionType: optionTypes.ce.value,
  transactionType: transactionTypes.buy.value,
  strikePrice: '',
  lot: '1',
  strikeIndex: 0,
  strikeLabel: 'ATM',
  price: 0,
  legExpiry: getCurrentWeeklyExpiryDate('BANKNIFTY'),
};

export const strategyImages = {
  shortStrangle: './images/options-builder-icon/neutral-short-strangle.png',
  shortStraddle: './images/options-builder-icon/neutral-short-straddle.png',
  buyCall: './images/options-builder-icon/bullish-buy-call.png',
  sellPut: './images/options-builder-icon/bullish-sell-put.png',
  bullCallSpread: './images/options-builder-icon/bullish-bull-call-spread.png',
  bullPutSpread: './images/options-builder-icon/bullish-bull-put-spread.png',
  buyPut: './images/options-builder-icon/bearish-buy-put.png',
  sellCall: './images/options-builder-icon/bearish-sell-call.png',
  bearPutSpread: './images/options-builder-icon/bearish-bear-put-spread.png',
  bearCallSpread: './images/options-builder-icon/bearish-bear-call-spread.png',
  ironButterfly: './images/options-builder-icon/neutral-iron-butterfly.png',
  ironCondor: './images/options-builder-icon/neutral-short-iron-condor.png',
};

export const strategyConfig = {
  bullish: {
    buyCall: {
      id: 'buyCall',
      name: 'Buy Call',
      image: strategyImages.buyCall,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
      ],
    },

    sellPut: {
      id: 'sellPut',
      name: 'Sell Put',
      image: strategyImages.sellPut,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
      ],
    },

    bullCallSpread: {
      id: 'bullCallSpread',
      name: 'Bull Call Spread',
      image: strategyImages.bullCallSpread,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 5, // otm 5
          strikeLabel: 'ATM + 5',
        },
      ],
    },

    bullPutSpread: {
      id: 'bullPutSpread',
      name: 'Bull Put Spread',
      image: strategyImages.bullPutSpread,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.pe.value,
          strikeIndex: -5,
          strikeLabel: 'ATM - 5',
        },
      ],
    },
  },

  bearish: {
    buyPut: {
      id: 'buyPut',
      name: 'Buy Put',
      image: strategyImages.buyPut,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.pe.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
      ],
    },

    sellCall: {
      id: 'sellCall',
      name: 'Sell Call',
      image: strategyImages.sellCall,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
      ],
    },

    bearPutSpread: {
      id: 'bearPutSpread',
      name: 'Bear Put Spread',
      image: strategyImages.bearPutSpread,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.pe.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: -5,
          strikeLabel: 'ATM - 5',
        },
      ],
    },

    bearCallSpread: {
      id: 'bearCallSpread',
      name: 'Bear Call Spread',
      image: strategyImages.bearCallSpread,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 5,
          strikeLabel: 'ATM + 5',
        },
      ],
    },
  },

  neutral: {
    shortStrangle: {
      id: 'shortStrangle',
      name: 'Short Strangle',
      image: strategyImages.shortStrangle,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 5,
          strikeLabel: 'ATM + 5',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: -5,
          strikeLabel: 'ATM - 5',
        },
      ],
    },

    shortStraddle: {
      id: 'shortStraddle',
      name: 'Short Straddle',
      image: strategyImages.shortStraddle,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
      ],
    },

    ironButterfly: {
      id: 'ironButterfly',
      name: 'Iron Butterfly',
      image: strategyImages.ironButterfly,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 0,
          strikeLabel: 'ATM',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.pe.value,
          strikeIndex: -5,
          strikeLabel: 'ATM - 5',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 5,
          strikeLabel: 'ATM + 5',
        },
      ],
    },

    ironCondor: {
      id: 'ironCondor',
      name: 'Iron Condor',
      image: strategyImages.ironCondor,
      optionConfigs: [
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 5,
          strikeLabel: 'ATM + 5',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.sell.value,
          optionType: optionTypes.pe.value,
          strikeIndex: -5,
          strikeLabel: 'ATM - 5',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.ce.value,
          strikeIndex: 8,
          strikeLabel: 'ATM + 8',
        },
        {
          ...defaultLegConfig,
          transactionType: transactionTypes.buy.value,
          optionType: optionTypes.pe.value,
          strikeIndex: -8,
          strikeLabel: 'ATM - 8',
        },
      ],
    },
  },
};

export const defaultStrategiesConfigs = {
  buyCall: strategyConfig.bullish.buyCall,
  sellPut: strategyConfig.bullish.sellPut,
  bullCallSpread: strategyConfig.bullish.bullCallSpread,
  bullPutSpread: strategyConfig.bullish.bullPutSpread,
  buyPut: strategyConfig.bearish.buyPut,
  sellCall: strategyConfig.bearish.sellCall,
  bearCallSpread: strategyConfig.bearish.bearCallSpread,
  bearPutSpread: strategyConfig.bearish.bearPutSpread,
  shortStraddle: strategyConfig.neutral.shortStraddle,
  shortStrangle: strategyConfig.neutral.shortStrangle,
  ironButterfly: strategyConfig.neutral.ironButterfly,
  ironCondor: strategyConfig.neutral.ironCondor,
};
