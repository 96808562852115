import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  instrumentLotSizeAndStrikeIntervalConfigs,
  placeOrderModes,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import { fetchBrokerConfigs } from 'habitual-analytics/brokers/index';
import { getPlaceOrderEligibleLists } from './config';

const PRICE = 'price';

const priceTickSizeConversion = (amount) => {
  return (Math.ceil(amount * 20) / 20).toFixed(2);
};

export const getEligibleProductCodeAndTypeList = (
  tradingSymbolObj,
  placeOrderMode = placeOrderModes.live
) => {
  const { productCodeLists, productTypeLists } = getPlaceOrderEligibleLists(
    tradingSymbolObj?.isEquity(),
    tradingSymbolObj?.isFuture(),
    tradingSymbolObj?.isOption(),
    placeOrderMode
  );

  const eligibleProductCodeList = _.filter(
    productCodeLists,
    ({ isShown }) => isShown
  );
  const eligibleProductTypeList = _.filter(
    productTypeLists,
    ({ isShown }) => isShown
  );

  return { eligibleProductCodeList, eligibleProductTypeList };
};

const priceOrPercentageConversion = (
  type,
  addtionalValue,
  actualPrice,
  orderTypeValue
) => {
  if (type === PRICE) {
    return priceTickSizeConversion(
      /* eslint-disable no-constant-binary-expression */
      Number(actualPrice) + orderTypeValue * Number(addtionalValue) ?? 0
    );
  }

  const updatedPrice = (Number(addtionalValue) / 100) * Number(actualPrice);
  return priceTickSizeConversion(
    /* eslint-disable no-constant-binary-expression */
    Number(actualPrice) + updatedPrice * orderTypeValue ?? 0
  );
};

export const getFormattedConfig = (placeOrderConfig) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const price = _.get(placeOrderConfig, 'ltp', 0);
  const trigPrice = _.get(placeOrderConfig, 'ltp', 0);
  const type = _.get(placeOrderConfig, 'type', '');
  const tradingSymbol = _.get(placeOrderConfig, 'tradingSymbol', '');
  const { symbol, expiryDate } =
    placeOrderConfig.tradingSymbolObj.getSymbolObject();

  return {
    userId: brokerClientId,
    exch: placeOrderConfig.tradingSymbolObj.isEquity() ? 'NSE' : 'NFO',
    pCode: 'MIS',
    price: getRoundedData(price),
    actualPrice: getRoundedData(price),
    qty: placeOrderConfig.tradingSymbolObj.isEquity()
      ? 1
      : _.get(
        instrumentLotSizeAndStrikeIntervalConfigs(symbol, expiryDate),
        'lot_size',
        50
      ),
    transactionType: _.get(
      placeOrderConfig,
      'transactionType',
      transactionTypes.buy.value
    ),
    prctyp: 'L',
    trigPrice: getRoundedData(trigPrice),
    complexty: 'Regular',
    type,
    ret: 'DAY',
    disCloseQty: 0,
    symbol,
    tradingSymbol,
    ltp: _.get(placeOrderConfig, 'ltp', 0),
    prevDayClose: _.get(placeOrderConfig, 'prevDayClose', 0),
  };
};

export const adjustedPlaceOrderConfigs = (placeOrderConfigs) => {
  const { limitPriceOrPercent, limitType, triggerPriceOrPercent, triggerType } =
    getDynamicAppConfigs().orderPreferences;

  return _.map(placeOrderConfigs, (placeOrderConfig) => {
    return {
      ...placeOrderConfig,
      trigPrice: priceOrPercentageConversion(
        triggerType,
        triggerPriceOrPercent,
        placeOrderConfig.actualPrice,
        placeOrderConfig.transactionType === transactionTypes.buy.value ? 1 : -1
      ),
      price: priceOrPercentageConversion(
        limitType,
        limitPriceOrPercent,
        placeOrderConfig.actualPrice,
        placeOrderConfig.transactionType === transactionTypes.buy.value ? 1 : -1
      ),
    };
  });
};

export const adjustedPlaceOrderConfig = (placeOrderConfig) => {
  const { limitPriceOrPercent, limitType, triggerPriceOrPercent, triggerType } =
    getDynamicAppConfigs().orderPreferences;

  return {
    ...placeOrderConfig,
    trigPrice: priceOrPercentageConversion(
      triggerType,
      triggerPriceOrPercent,
      placeOrderConfig.actualPrice,
      placeOrderConfig.transactionType === transactionTypes.buy.value ? 1 : -1
    ),
    price: priceOrPercentageConversion(
      limitType,
      limitPriceOrPercent,
      placeOrderConfig.actualPrice,
      placeOrderConfig.transactionType === transactionTypes.buy.value ? 1 : -1
    ),
  };
};

export const getOrderPreferencesAppliedPlaceOrderConfigs = (
  placeOrderConfigs
) => {
  const {
    lot: orderPreferenceLot,
    pCode,
    prctyp,
    ret,
  } = getDynamicAppConfigs().orderPreferences;

  return adjustedPlaceOrderConfigs(
    _.map(placeOrderConfigs, (placeOrderConfig) => {
      const {
        lot: placeOrderLot,
        tradingSymbolObj,
        pCode: placeOrderPCode,
      } = placeOrderConfig;
      const updatedLot = placeOrderLot ?? orderPreferenceLot;
      const config = {
        ...placeOrderConfig,
        ...getFormattedConfig(placeOrderConfig),
      };
      const { eligibleProductCodeList, eligibleProductTypeList } =
        getEligibleProductCodeAndTypeList(tradingSymbolObj);

      const selectedProductType = _.find(
        eligibleProductTypeList,
        (productTypeList) => productTypeList.value === prctyp
      );
      const selectedProductCode = _.find(
        eligibleProductCodeList,
        (productCodeList) => productCodeList.value === pCode
      );

      const brokerProductCode = fetchBrokerConfigs(
        'getDefaultConfigs',
        tradingSymbolObj?.isEquity(),
        tradingSymbolObj?.isFuture()
      )?.productCode;

      const updatedProductCode = placeOrderPCode ?? brokerProductCode;

      return {
        ...placeOrderConfig,
        ...config,
        pCode:
          updatedProductCode ?? (selectedProductCode?.value ? pCode : 'MIS'),
        prctyp: selectedProductType?.value ? prctyp : 'L',
        ret,
        trigPrice: config.trigPrice,
        price: config.price,
        qty: config.qty * (updatedLot ? Number(updatedLot) : 1),
      };
    })
  );
};
