import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from '../resturls';
import moment from 'moment';
import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import { DATE_FORMAT, EXPIRY_DATE_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import { DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX, NUMBER_REGEX, 
  instrumentFromSATradingSymbol } from 'habitual-analytics/models/instrument/factory';
import { getUpComingExpiryDates } from 'habitual-analytics/dateUtils/expiryDateCalculations';
import _ from 'lodash';

let formattedQueryParams;

const formatInstrument = (instrument) => {
  const matches = instrument.match(DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX);
  const [, symbol, expiryDate, strikePrice, optionType] = matches || [];
  const formattedExpiryDate = moment(expiryDate, EXPIRY_DATE_FORMAT).format(
    DATE_FORMAT
  );

  if (optionType === 'FUT') return `FUT|${symbol}|I`;
  else if (strikePrice)
    return `OPT|${symbol}|${formattedExpiryDate}|${strikePrice}|${optionType}`;
  else return `EQ|${instrument}`;
};

const createFetchParameters = (datetime) => {
  return {
    method: 'POST',
    body: JSON.stringify({
      instruments: _.join(formattedQueryParams, ','),
      datetime: `${datetime}`,
      candleInterval: '1m',
    }),
  };
};

const fetchData = async (parameters) => {
  const { TICKS_POINT_IN_TIME } = REST_URLS();

  const { data } = await wrappedFetch(TICKS_POINT_IN_TIME, parameters).then(
    (response) => response.json()
  );
  return data;
};

export const getInstrumentsBasedHistoricalData = async (instruments) => {
  const combinedData = {};
  const formattedCombinedData = {
    marketData: {},
    additionalData: {},
  };
  const {
    marketStartTime,
    marketCurrentTime,
    marketPrevDayEndTime,
    firstOiStartTime,
  } = getMarketTimingConfigs();

  formattedQueryParams = _.map(_.split(instruments, ','), (instrument) =>
    formatInstrument(instrument)
  );

  const marketStartTimeParameters = createFetchParameters(marketStartTime, '1m');
  const firstOiStartTimeParameters = createFetchParameters(firstOiStartTime, '1m');
  const marketCurrentTimeParameters = createFetchParameters(marketCurrentTime, '1m');
  const marketPrevDayEndTimeParameters = createFetchParameters(marketPrevDayEndTime, '1m');

  const marketStartTimeData = await fetchData(marketStartTimeParameters);
  const firstOiStartTimeData = await fetchData(firstOiStartTimeParameters);
  const marketCurrentTimeData = await fetchData(marketCurrentTimeParameters);
  const marketPrevDayEndTimeData = await fetchData(marketPrevDayEndTimeParameters);
  
  _.map(
    _.flattenDeep([
      marketStartTimeData,
      firstOiStartTimeData,
      marketCurrentTimeData,
      marketPrevDayEndTimeData
    ]),
    (instrumentData) => {
      _.map(instrumentData, (data, key) => {
        combinedData[key] = combinedData[key]
          ? _.flattenDeep([combinedData[key], data])
          : [data];
      });
    }
  );

  _.map(combinedData, (data, key) => {
    let formattedTradingSymbol;  
    const instrument = _.replace(key, '-I', '');

    if(_.includes(key, '-I')) {
      const monthlyExpiryDate = moment(getUpComingExpiryDates('M', instrument), DATE_FORMAT)
        .format(EXPIRY_DATE_FORMAT)
        .toUpperCase();
      formattedTradingSymbol = `${instrument}${monthlyExpiryDate}FUT`;
    } else if(_.some(key, char => NUMBER_REGEX.test(char))){
      formattedTradingSymbol = instrumentFromSATradingSymbol(key).toString();
    }
    else formattedTradingSymbol = key;

    const firstOpen = data?.[0]?.open || data?.[3]?.close;
    const firstOi = data?.[1]?.open_interest || data?.[3]?.open_interest;
    
    formattedCombinedData.additionalData[`${formattedTradingSymbol}`] = {
      firstOi: firstOi,
      firstOpen: firstOpen,
      prevDayLtp: _.get(data, '3.close', 0),
      prevDayOi: _.get(data, '3.open_interest', 0),
    };

    formattedCombinedData.marketData[`${formattedTradingSymbol}`] = {
      ltp: _.get(data, '2.close', 0),
      oi: _.get(data, '2.open_interest', 0),
      currentDayOpen: firstOpen,
      previousDayClose: _.get(data, '3.close', 0),
    };
  }); 

  return formattedCombinedData;
};
