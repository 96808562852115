import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from '../resturls';

export const getInstrumentsBasedLiveData = (instruments) => {
  const { GET_LIVE_DATA } = REST_URLS();

  const parameters = {
    method: 'POST',
    body: JSON.stringify({
      instruments,
    }),
  };

  const uri = `${GET_LIVE_DATA}`;

  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const newMarketData = {};
      const newAdditionalData = {};
      const additionalData = result.additionalData || {};

      for (const key in additionalData) {
        const item = additionalData[key];
        newAdditionalData[key] = {
          ...item,
          firstOpen: item?.firstOpen || item?.prevDayLtp,
          firstOi: item?.firstOi || item?.prevDayOi,
        };
      }

      for (const key in result.marketData) {
        const item = result.marketData[key];
        newMarketData[key] = {
          ...item,
          currentDayOpen: newAdditionalData[key]?.firstOpen,
          previousDayClose: newAdditionalData[key]?.prevDayLtp,
        };
      }

      return {
        marketData: newMarketData,
        additionalData: newAdditionalData,
      };
    });
};
