import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  isNse,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
} from '../tradingSymbolParser';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { arrayOfInstruments } from 'habitual-analytics/constants/instruments';

import Instrument from 'habitual-analytics/models/instrument/index';
import { isinCode } from 'zeequant-constants';

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs?.placed?.value;

  switch (_.lowerCase(orderDetail.status)) {
    case 'cancelled':
      status = statusConfigs?.cancelled?.value;
      break;
    case 'complete':
      status = statusConfigs?.executed?.value;
      break;
    case 'rejected':
      status = statusConfigs?.failed?.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.Status,
      orderDetail.Qty,
      orderDetail.Fillshares
    ):
      status = statusConfigs?.partiallyExecuted?.value;
      break;
    default:
      status = statusConfigs?.placed?.label;
      break;
  }

  return status;
};

const parseProductCode = (productCode) => {
  let type;
  switch (_.trim(_.toLower(productCode))) {
    case 'cnc':
    case 'nrml':
      type = 'D';
      break;
    case 'mis':
      type = 'I';
      break;
    default:
      type = productCode;
  }
  return type;
};

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'LIMIT';
      break;
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl-m':
      type = 'SL-M';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const defaultProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'limit':
      type = 'L';
      break;
    case 'market':
      type = 'MKT';
      break;
    case 'sl-m':
      type = 'SL-M';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trigger_price', ''));
  const price = convertToNumber(_.get(orderDetail, 'price', ''));
  const tradedQty = _.get(orderDetail, 'filled_quantity', 0);
  const quantity = Number(_.get(orderDetail, 'quantity', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;
  const tradedTime = _.get(orderDetail, 'order_timestamp', '').split(' ')?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'status_message', '');
  const transType = _.toLower(_.get(orderDetail, 'transaction_type', ''));

  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      transType === 'sell'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'order_id', ''),
      defaultProductType: defaultProductType(productType),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product', '');
  const tradedQuantity = Number(_.get(orderDetail, 'quantity', 0));
  const quantity = Number(_.get(orderDetail, 'quantity', 0));
  const status = statusConfigs.executed.label;
  const tradedTime = _.get(orderDetail, 'order_timestamp', '').split(' ')?.[1];
  const transType = _.toLower(_.get(orderDetail, 'transaction_type', ''));

  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      transType === 'sell'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'average_price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const ltp = convertToNumber(_.get(orderDetail, 'last_price'));
  const qty = Number(_.get(orderDetail, 'quantity', 0));
  const buyAvg = convertToNumber(_.get(orderDetail, 'buy_price', 0)).toFixed(2);
  const sellAvg = convertToNumber(_.get(orderDetail, 'sell_price', 0)).toFixed(
    2
  );
  const realisedprofitloss = _.round(_.get(orderDetail, 'realised', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const orderValue = type === transactionTypes.buy.value ? buyAvg : sellAvg;
  const profitAndLoss = _.round(_.get(orderDetail, 'pnl', 0), 2);
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - orderValue : orderValue - ltp;
  const profitLoss =
    qty === 0
      ? profitAndLoss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'product', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_price',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'trading_symbol', '');
  const quantity = _.get(orderDetail, 'quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'last_price', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const tradingSymbolObj = getFormattedTradingSymbolObject(
    `${tradingSymbol}-EQ`
  );

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'last_price',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfigs) => {
  // completely have to clean up this code
  const formattedOrderConfigs = await Promise.all(
    _.map(orderConfigs, async (orderConfig) => {
      const tradingSymbolObj = orderConfig?.tradingSymbolObj;
      const newTradingSymbolObj = new Instrument({
        symbol: tradingSymbolObj?.symbol,
        expiryDate: tradingSymbolObj?.expiryDate,
        strikePrice: tradingSymbolObj?.strikePrice,
        optionType: tradingSymbolObj?.optionType,
      });
      const formattedExchangeTokenTradingSymbol =
        parseExchangeTokenTradingSymbol(newTradingSymbolObj);
      const symbolId = await getExchangeToken(
        formattedExchangeTokenTradingSymbol
      );
      const prcType = _.toLower(_.get(orderConfig, 'prctyp', ''));

      return {
        quantity: _.get(orderConfig, 'qty', ''),
        product: parseProductCode(_.get(orderConfig, 'pCode', '')),
        validity: _.get(orderConfig, 'ret', ''),
        price: _.includes(['mkt', 'sl-m'], prcType)
          ? 0
          : _.get(orderConfig, 'price', ''),
        instrument_token:
          orderConfig?.exch === 'NFO'
            ? `NSE_FO|${symbolId}`
            : `NSE_EQ|${isinCode[tradingSymbolObj?.symbol]}`,
        order_type: parseProductType(prcType),
        transaction_type: _.get(
          orderConfig,
          'transactionType',
          ''
        ).toUpperCase(),
        disclosed_quantity: _.get(orderConfig, 'disCloseQty', ''),
        trigger_price: !_.includes(['mkt', 'l'], prcType)
          ? _.get(orderConfig, 'trigPrice', '')
          : 0,
        is_amo: false,
      };
    })
  );
  return formattedOrderConfigs;
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
};
