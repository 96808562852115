import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import WithErrorBoundary from 'globals/components/withErrorBoundary/index';
import { initializeSentry } from 'globals/utils/sentry';
import WithEnvs from 'globals/components/withEnvs';
import { reactToastify, TOASTIFY_TYPES } from 'globals/utils/reactToastify';
import Routes from '../routes';
import withResponsiveProvider from 'globals/components/withResponsiveProvider';

const App = () => {
  useEffect(() => {
    initializeSentry();
  }, []);

  const renderFlashMessage = () => {
    const { alert, notice } = _.get(window, 'instaConstants.flashMessage', {});

    if (!_.isEmpty(alert)) {
      reactToastify(TOASTIFY_TYPES.ERROR, alert);
    }

    if (!_.isEmpty(notice)) {
      reactToastify(TOASTIFY_TYPES.INFO, notice);
    }

    // Empty flashMessage in window after showing
    window.instaConstants.flashMessage = {};
    return null;
  };

  return (
    <>
      {renderFlashMessage()}
      <Routes />
      <ToastContainer />
    </>
  );
};

export default WithErrorBoundary(WithEnvs(withResponsiveProvider(App)));
