import {
  DATE_FORMAT,
  EXPIRY_DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import moment from 'moment';
import {
  getCurrentYearExpiryDates,
  getExpiredExpiryDates,
  getMonthBasedExpiryDates,
  getUpComingExpiryDates,
} from 'habitual-analytics/dateUtils/expiryDateCalculations';
import _ from 'lodash';
import { MonthNamesToIndex } from 'habitual-analytics/constants/habitual-configs';
import Instrument from '.';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';

export const DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX =
  /^([a-zA-Z-&]+)?(\d{2}[a-zA-Z]{3}\d{2})?(\d+\.?\d*)?([a-zA-Z]{2,3})?$/;
const SA_TRADING_SYMBOL_REGEX = new RegExp(
  /(\D+)(\d{4}-\d{2}-\d{2})(\d+\.?\d*)([A-Z]+)/
);
const WATCHLIST_TRADING_SYMBOL_REGEX = new RegExp(
  /^([a-zA-Z-&]+)?(\d{2}[A-Z]{3}\d{4})?(\d+\.?\d*)?([a-zA-Z]{2,3})?$/
);
const EQUITY_SYMBOL_FORMAT_REGEX = /^([A-Z0-9-&]+)(-EQ)?$/;
const SPLIT_STRING_NUMBER_REGEX = /(\d+|[A-Z]+)/;
export const NUMBER_REGEX = /^\d+$/;

// BANKNIFTY2022-04-033000CE
export const instrumentFromSATradingSymbol = (saTradingSymbol) => {
  const match = saTradingSymbol.match(SA_TRADING_SYMBOL_REGEX);
  if (!match) {
    throw ('Could not parse sa trading symbol', saTradingSymbol);
  }
  const symbol = match[1];
  const expiryDate = match[2];
  const strikePrice = match[3];
  const optionType = match[4];

  return new Instrument({
    symbol,
    expiryDate,
    strikePrice,
    optionType,
  });
};

export const watchlistTradingSymbol = (saTradingSymbol) => {
  const match = saTradingSymbol.match(WATCHLIST_TRADING_SYMBOL_REGEX);
  if (!match) {
    throw ('Could not parse sa trading symbol', saTradingSymbol);
  }
  const symbol = match[1];
  const expiryDate = match[2];
  const strikePrice = match[3];
  const optionType = match[4];

  return new Instrument({
    symbol,
    expiryDate: moment(expiryDate, 'DDMMMYYYY')
      .format(DATE_FORMAT)
      .toUpperCase(),
    strikePrice,
    optionType,
  });
};

// BANKNIFTY17APR2445000CE or BANKNIFTY17APR25FUT or BANKNIFTY
export const displayInstrumentToSATradingSymbol = (displayInstrument) => {
  const matches = displayInstrument.match(
    DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX
  );
  if (!matches) {
    return new Instrument({
      symbol: displayInstrument,
      expiryDate: undefined,
      strikePrice: undefined,
      optionType: undefined,
    });
  }
  const [, symbol, date, strike, optionType] = matches;

  return new Instrument({
    symbol,
    expiryDate: date
      ? moment(date, EXPIRY_DATE_FORMAT).format(DATE_FORMAT)
      : date,
    strikePrice: strike,
    optionType: optionType === 'FUT' ? undefined : optionType,
  });
};

// BANKNIFTY2350444700CE // BANKNIFTY23MAY49000CE // BANKNIFTY27MAYFUT
export const withoutMonthOrMonthNumberFormatSymbolToSATradingSymbol = (
  tradingSymbol
) => {
  const symbol = tradingSymbol.match(/^[a-zA-Z-&]+/)[0];
  // chanhe this to regex.

  const expiry = tradingSymbol.substring(symbol.length, symbol.length + 5);
  const optionType = _.endsWith(tradingSymbol, 'FUT')
    ? undefined
    : tradingSymbol.slice(-2);
  const strikePrice = tradingSymbol.slice(
    symbol.length + 5,
    _.endsWith(tradingSymbol, 'FUT') ? -3 : -2
  );

  const getExpiryDate = () => {
    const splittedArray = expiry
      .split(SPLIT_STRING_NUMBER_REGEX)
      .filter(Boolean);
    if (splittedArray.length === 1) {
      const regex = /^(\d{2})(\d)(\d{2})$/;
      const [, year, month, day] = expiry.match(regex);

      return moment(`${year}-${month}-${day}`, 'YY-M-DD').format(DATE_FORMAT);
    }

    const expiryMonth = expiry?.slice(2, 5);
    const expiryDates =
      _.parseInt(moment(expiry, 'YY').format('YYYY')) ===
      getCurrentDate().year()
        ? getCurrentYearExpiryDates(symbol)
        : getExpiredExpiryDates(symbol);

    const currentMonth = MonthNamesToIndex[expiryMonth];

    return _.last(getMonthBasedExpiryDates(currentMonth, expiryDates));
  };

  return new Instrument({
    symbol,
    expiryDate: getExpiryDate(expiry),
    strikePrice: !strikePrice ? undefined : Number(strikePrice),
    optionType,
  });
};

// NIFTY50-EQ or SAIL-EQ
export const equitySymbolToSATradingSymbol = (tradingSymbol) => {
  const [, symbol] = tradingSymbol.match(EQUITY_SYMBOL_FORMAT_REGEX);
  return new Instrument({ symbol: _.replace(symbol, '-EQ', '') });
};

// Make instrument format for backend api * array of objects
export const getTradingSymbolObjsToApiFormatString = (configs) => {
  return _.chain(configs)
    .map(({ tradingSymbolObj }) => {
      if (tradingSymbolObj.isEquity()) {
        return `EQ|${tradingSymbolObj.symbol}`;
      }
      if (tradingSymbolObj.isFuture()) {
        return `FUT|${tradingSymbolObj.symbol}|I`;
      }
      const { symbol, expiryDate, strikePrice, optionType } =
        tradingSymbolObj.getSymbolObject();
      return `OPT|${symbol}|${expiryDate}|${strikePrice}|${optionType}`;
    })
    .toString()
    .value();
};

export const getCurrentMonthFutureTradingSymbol = (symbol) => {
  const currentExpiryDate = getUpComingExpiryDates('M', symbol)?.[0];
  return new Instrument({
    symbol,
    expiryDate: currentExpiryDate,
    optionType: 'FUT',
    strikePrice: null,
  });
};

export const getTradingSymbolObjToDBFormat = (tradingSymbolObj) => {
  if (tradingSymbolObj.isEquity()) {
    return `${tradingSymbolObj.symbol}`;
  }
  if (tradingSymbolObj.isFuture()) {
    return `${tradingSymbolObj.symbol}-I`;
  }
  const { symbol, expiryDate, strikePrice, optionType } =
    tradingSymbolObj.getSymbolObject();
  return `${symbol}${expiryDate}${strikePrice}${optionType}`;
};
