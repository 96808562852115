const URI = 'https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api';
const BASE_URI = 'https://ant.aliceblueonline.com/';
const PROFILE_PATH = '/rest/AliceBlueAPIService/api/customer/accountDetails';
const VALID_AUTHCODE_PATH = '/rest/AliceBlueAPIService/sso/getUserDetails';

const RestEndPoints = {
  GET_HOLDINGS: `${URI}/positionAndHoldings/holdings`,
  UPDATE_POSITION_BOOK: `${URI}/positionAndHoldings/positionBook`,
  GET_TRADE_BOOK: `${URI}/placeOrder/fetchTradeBook`,
  GET_ORDER_BOOK: `${URI}/placeOrder/fetchOrderBook`,
  PLACE_ORDER: `${URI}/placeOrder/executePlaceOrder`,
  BASE_URI,
  PROFILE_PATH: `${BASE_URI}${PROFILE_PATH}`,
  VALID_AUTHCODE_PATH: `${BASE_URI}${VALID_AUTHCODE_PATH}`,
  CANCEL_PLACE_ORDER: `${URI}/placeOrder/cancelOrder`,
};

export default RestEndPoints;
