import _ from 'lodash';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import moment from 'moment';
import {
  transactionTypes,
  statusConfigs,
} from 'habitual-analytics/constants/habitual-configs';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  displayInstrumentToSATradingSymbol,
  equitySymbolToSATradingSymbol,
} from 'habitual-analytics/models/instrument/factory';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
  isNse,
} from '../utils';
import { parsePlaceOrderTradingSymbol } from './workmate';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { arrayOfInstruments } from 'habitual-analytics/constants/instruments';

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs?.placed?.value;

  switch (_.lowerCase(orderDetail.orderstatus)) {
    case 'cancelled':
      status = statusConfigs?.cancelled?.value;
      break;
    case 'complete':
      status = statusConfigs?.executed?.value;
      break;
    case 'rejected':
      status = statusConfigs?.failed?.value;
      break;
    case isOrderPartiallyExecuted(
      status,
      orderDetail.quantity,
      orderDetail.filledshares
    ):
      status = statusConfigs?.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs?.placed?.label;
      break;
  }

  return status;
};

const parseOrderType = (orderType) => {
  switch (orderType) {
    case 'Limit':
    case 'L':
      return 'LIMIT';
    case 'MKT':
      return 'MARKET';
    case 'SL':
      return 'STOPLOSS_LIMIT';
    case 'SL-M':
      return 'STOPLOSS_MARKET';
    default:
      return orderType;
  }
};

const parseProductType = (productType) => {
  switch (productType) {
    case 'cnc':
      return 'DELIVERY';
    case 'nrml':
      return 'CARRYFORWARD';
    case 'mis':
      return 'INTRADAY';
    default:
      return productType;
  }
};

const getTradingSymbolObj = (tradingSymbol) => {
  return {
    tradingSymbolObj: _.endsWith(tradingSymbol, 'EQ')
      ? equitySymbolToSATradingSymbol(tradingSymbol)
      : displayInstrumentToSATradingSymbol(tradingSymbol),
  };
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const tradedPrice = convertToNumber(_.get(orderDetail, 'averageprice', 0));
  const lotSize = Number(_.get(orderDetail, 'lotsize', 0));
  const tradedTime = _.get(orderDetail, 'updatetime', '').split(' ')[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const tradedQuantity = _.get(orderDetail, 'filledshares', 0) / lotSize;
  const Quantity = _.get(orderDetail, 'quantity', 0) / lotSize;
  const tradedType = _.get(orderDetail, 'transactiontype', '');
  const isCancellableOrder = getCancellableOrderStatus(status);
  const productType = _.get(orderDetail, 'producttype', '');
  // const orderType = _.get(orderDetail, 'ordertype', '');

  const tradingSymbolObj = getTradingSymbolObj(tradingSymbol)?.tradingSymbolObj;
  const failedReason = _.get(orderDetail, 'text', '');

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.toLower(tradedType) === 'buy'
        ? transactionTypes?.buy.value
        : transactionTypes?.sell?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productType}`,
      qty: `${tradedQuantity} / ${Quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'orderid', ''),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const tradedTime = _.get(orderDetail, 'filltime', '');
  const type = _.get(orderDetail, 'transactiontype', '');
  const productType = _.get(orderDetail, 'producttype', '');
  const tradedPrice = convertToNumber(_.get(orderDetail, 'fillprice', 0));
  const status = statusConfigs.executed.label;
  const lotSize = Number(_.get(orderDetail, 'marketlot', 0));
  const tradingSymbolObj = getTradingSymbolObj(tradingSymbol)?.tradingSymbolObj;

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.toLower(type) === 'buy'
        ? transactionTypes?.buy.value
        : transactionTypes?.sell?.value,
    status,
    extraDetails: {
      product: productType,
      tradedPrice,
      qty: (_.get(orderDetail, 'fillsize', 0) / lotSize).toString(),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'netqty', 0));
  const type =
    Number(_.get(orderDetail, 'netqty', 0)) > 0
      ? transactionTypes.buy.value
      : transactionTypes.sell.value;
  const profitAndLoss = _.round(_.get(orderDetail, 'pnl', 0), 2);
  const realisedprofitloss = _.round(_.get(orderDetail, 'realised', 0), 2);
  const buyAvgPrice = convertToNumber(
    _.get(orderDetail, 'totalbuyavgprice', 0)
  );
  const sellAvgPrice = convertToNumber(
    _.get(orderDetail, 'totalsellavgprice', 0)
  );
  const averagePrice =
    type === transactionTypes.buy.value ? buyAvgPrice : sellAvgPrice;
  const currentProfitLoss =
    type === transactionTypes.buy.value
      ? ltp - averagePrice
      : averagePrice - ltp;

  const profitLoss =
    qty === 0
      ? profitAndLoss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;
  const { tradingSymbolObj } = getTradingSymbolObj(tradingSymbol);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    qty,
    ltp,
    profitLoss,
    buyAvg: buyAvgPrice,
    sellAvg: sellAvgPrice,
    extraDetails: {
      averagePrice,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'producttype', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      product: _.get(orderDetail, 'producttype', ''),
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');

  if (!isNse(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'tradingsymbol', '');
  const averagePrice = convertToNumber(_.get(orderDetail, 'averageprice', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const quantity = _.get(orderDetail, 'quantity', 0);
  const profitLoss = getRoundedData((ltp - averagePrice) * quantity);
  const netChg = getRoundedData((profitLoss / averagePrice) * 100);
  const { tradingSymbolObj } = getTradingSymbolObj(tradingSymbol);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    profitLoss,
    extraDetails: {
      quantity: `${quantity}`,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
      averagePrice,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = parsePlaceOrderTradingSymbol(tradingSymbolObj);
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);
  const orderType = parseOrderType(_.get(orderConfig, 'prctyp', ''));
  const productCode = _.get(orderConfig, 'pCode', '');

  return {
    variety: _.includes(
      ['STOPLOSS_LIMIT', 'STOPLOSS_MARKET'],
      _.toUpper(orderType)
    )
      ? 'STOPLOSS'
      : 'NORMAL',
    tradingsymbol: formattedTradingSymbol,
    symboltoken: symbolId,
    transactiontype: _.get(orderConfig, 'transactionType', '').toUpperCase(),
    exchange: _.get(orderConfig, 'exch', ''),
    ordertype: orderType,
    producttype: parseProductType(_.toLower(productCode)),
    duration: _.get(orderConfig, 'ret', '').toUpperCase(),
    price: _.get(orderConfig, 'price', ''),
    triggerprice: _.get(orderConfig, 'trigPrice', ''),
    quantity: _.get(orderConfig, 'qty', ''),
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parseOrderDetails,
  parseOrderBook,
  parseTradeBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
};
