import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import RestEndPoints from './RestEndPoints';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseMarginCalculator,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';

export const REQUEST_PHASE_URL = () => {
  // todo for mobile app
  return '';
};

const requestHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const mandatoryRequestData = (brokerClientId) =>
  JSON.stringify({ uid: brokerClientId, actid: brokerClientId });

const fetchAccessToken = () => {
  // todo for mobile app
  return {};
};

const isValidAccessToken = () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const requestBody = `jData=${mandatoryRequestData(
    brokerClientId
  )}&jKey=${brokerClientAccessToken}`;

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  const uri = RestEndPoints.PROFILE_PATH;
  return wrappedFetch(uri, parameters)
    .then((res) => res.ok)
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = () => {
  const ApiUrl = RestEndPoints.GET_ORDER_BOOK;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const requestBody = `jData=${mandatoryRequestData(
    brokerClientId
  )}&jKey=${brokerClientAccessToken}`;

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => ({
      orderBookDetails: _.compact(
        _.orderBy(parseOrderDetails(result, 'order'), ['time'], ['desc'])
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const ApiUrl = RestEndPoints.GET_TRADE_BOOK;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const requestBody = `jData=${mandatoryRequestData(
    brokerClientId
  )}&jKey=${brokerClientAccessToken}`;

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseOrderDetails(result, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const ApiUrl = RestEndPoints.UPDATE_POSITION_BOOK;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const requestBody = `jData=${mandatoryRequestData(
    brokerClientId
  )}&jKey=${brokerClientAccessToken}`;

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const ApiUrl = RestEndPoints.GET_HOLDINGS;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const requestData = {
    uid: brokerClientId,
    actid: brokerClientId,
    prd: 'C',
  };
  const requestBody = `jData=${JSON.stringify(
    requestData
  )}&jKey=${brokerClientAccessToken}`;

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(parseOrderDetails(result, 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const ApiUrl = RestEndPoints.PLACE_ORDER;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, (orderConfig) => {
      const orderDetails = parsePlaceOrder(orderConfig, brokerClientId);
      const requestBody = `jData=${JSON.stringify(
        orderDetails
      )}&jKey=${brokerClientAccessToken}`;

      const parameters = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
        isOrderPlacerApi: true,
      };

      return wrappedFetch(ApiUrl, parameters)
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ stat, norenordno }) => (stat === 'Ok' ? norenordno : null))
    .filter((value) => value !== null)
    .value();
};

const cancelPlaceOrder = ({ orderNo }) => {
  const ApiUrl = RestEndPoints.CANCEL_PLACE_ORDER;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const requestData = {
    uid: brokerClientId,
    actid: brokerClientId,
    norenordno: orderNo,
  };
  const requestbody = `jData=${JSON.stringify(
    requestData
  )}&jKey=${brokerClientAccessToken}`;

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestbody,
    redirect: 'follow',
  };

  return wrappedFetch(ApiUrl, parameters).then((response) => response.json());
};

const fetchMarginCalculator = async (orderConfigs) => {
  const ApiUrl = RestEndPoints.MARGIN_CALCULATOR;

  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;

  const formattedResponse = await Promise.all(
    _.map(orderConfigs, (orderConfig) => {
      const orderDetails = parseMarginCalculator(orderConfig, brokerClientId);
      const requestBody = `jData=${JSON.stringify(
        orderDetails
      )}&jKey=${brokerClientAccessToken}`;

      const parameters = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      };

      return wrappedFetch(ApiUrl, parameters)
        .then((response) => response.json())
        .then((data) => data)
        .catch((e) => [e.message]);
    })
  );

  const totalMarginRequired =
    _.chain(formattedResponse)
      .map(({ ordermargin }) => parseFloat(ordermargin))
      .sum()
      .value() || 0;

  const totalMarginAvailable =
    _.chain(formattedResponse)
      .map(({ cash }) => parseFloat(cash))
      .sum()
      .value() || 0;

  return { totalMarginRequired, totalMarginAvailable };
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  fetchAccessToken,
  cancelPlaceOrder,
  fetchMarginCalculator,
};
