import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import RestEndPoints from './RestEndPoints';
import {
  parseCancelOrder,
  parseMarginAvailable,
  parseMarginCalculator,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';

const getBaseUri = (isProfile = false) => {
  const { 
    NUVAMA_VENDOR_LOGIN_URL,
    NUVAMA_API_URL
  } = getDynamicAppConfigs().envs;

  if(isProfile) {
    return NUVAMA_VENDOR_LOGIN_URL;
  }

  return NUVAMA_API_URL;
};

const getRequestHeaders = () => {
  const { NUVAMA_API_KEY, NUVAMA_APP_ID, NUVAMA_SRC_TOKEN } =
    getDynamicAppConfigs().envs;
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  return {
    appidkey: NUVAMA_APP_ID,
    Source: NUVAMA_API_KEY,
    SourceToken: NUVAMA_SRC_TOKEN,
    Authorization: brokerClientAccessToken,
  };
};

const isValidAccessToken = () => {
  return fetchOrderBook()
    .then(({ isValid }) => isValid || false)
    .catch(() => false);
};

const fetchOrderBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.GET_ORDER_BOOK}/${brokerClientId}/v1`;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(_.get(result, 'data.ord', []), 'order'),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
        isValid: result?.data
          ? true
          : _.includes(
            ['Seems like there are no orders in your order book'],
            result?.error?.errMsg
          ),
      };
    });
};

const fetchTradeBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.GET_TRADE_BOOK}/v1/${brokerClientId}`;
  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const { data } = result;

      return {
        tradeBookDetails: _.compact(
          _.orderBy(parseOrderDetails(data?.trade, 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.UPDATE_POSITION_BOOK}/${brokerClientId}`;

  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then((result) => {
      const { data } = result;

      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(data?.pos, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    headers: {
      ...getRequestHeaders(),
    },
  };

  const uri = `${getBaseUri()}/${RestEndPoints.GET_HOLDINGS}/${brokerClientId}`;

  return wrappedFetch(uri, parameters)
    .then((response) => response.json())
    .then(({ data }) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(data?.rmsHdg, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const cancelPlaceOrder = ({ orderDetails, orderNo }) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const parameters = {
    method: 'PUT',
    headers: {
      ...getRequestHeaders(),
    },
    body: JSON.stringify(parseCancelOrder(orderDetails, orderNo)),
  };

  const uri = `${getBaseUri()}/${RestEndPoints.CANCEL_PLACE_ORDER}/${brokerClientId}`;

  return wrappedFetch(uri, parameters).then((response) => response.json());
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const { NUVAMA_API_KEY } = getDynamicAppConfigs().envs;

  const uri = `${getBaseUri()}/${RestEndPoints.PLACE_ORDER}/${brokerClientId}`;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const bodyDetails = await parsePlaceOrder(orderConfig, NUVAMA_API_KEY);
      const parameters = {
        method: 'POST',
        headers: {
          ...getRequestHeaders(),
        },
        body: JSON.stringify(bodyDetails),
      };

      return wrappedFetch(uri, parameters)
        .then((response) => response.json())
        .then((result) => result)
        .catch((e) => [e]);
    })
  );

  return _.map(formattedConfig, ({ data }) => data?.oid);
};

const fetchMarginCalculator = async (orderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const { NUVAMA_API_KEY } = getDynamicAppConfigs().envs;
  const ApiUrl = `${getBaseUri()}/${RestEndPoints.MARGIN_CALCULATOR}/${brokerClientId}`;

  const formattedResponse = await Promise.all(
    _.map(orderConfigs, async (orderConfig) => {
      const orderDetails = await parseMarginCalculator(
        orderConfig,
        NUVAMA_API_KEY
      );
      const parameters = {
        method: 'POST',
        headers: {
          ...getRequestHeaders(),
        },
        body: JSON.stringify(orderDetails),
      };
      return wrappedFetch(ApiUrl, parameters)
        .then((response) => response.json())
        .then(({ data }) => data)
        .catch((e) => [e.message]);
    })
  );
  const totalMarginRequired =
    _.chain(formattedResponse)
      .map(({ ordMargin }) => parseFloat(ordMargin))
      .sum()
      .value() || 0;

  const totalMarginAvailable =
    _.map(formattedResponse, ({ availCash }) => parseFloat(availCash))[0] || 0;

  return { totalMarginRequired, totalMarginAvailable };
};

const fetchMarginAvailable = async () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const ApiUrl = `${getBaseUri()}/${RestEndPoints.MARGIN_CALCULATOR}/${brokerClientId}`;

  const orderDetails = parseMarginAvailable();
  const parameters = {
    method: 'POST',
    headers: {
      ...getRequestHeaders(),
    },
    body: JSON.stringify(orderDetails),
  };
  const formattedResponse = await wrappedFetch(ApiUrl, parameters)
    .then((response) => response.json())
    .then(({ data }) => data)
    .catch((e) => [e.message]);

  const totalMarginRequired = 0;
  const totalMarginAvailable = _.toNumber(
    _.get(formattedResponse, 'availCash', 0)
  );

  return { totalMarginRequired, totalMarginAvailable };
};

export {
  isValidAccessToken,
  fetchOrderBook,
  fetchTradeBook,
  fetchPositionBook,
  placeOrder,
  fetchHoldingBook,
  cancelPlaceOrder,
  fetchMarginCalculator,
  fetchMarginAvailable,
};
