import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseCancelOrder,
  parseModifyOrder,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';

  return `${baseUrl}?broker=hdfcsky&method=${method}${queryString}${pathString}`;
};

const isValidAccessToken = () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      'User-Agent': 'PostmanRuntime/7.32.3',
    },
  };

  return wrappedFetch(
    getRequestUri(
      'validateUser',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.status === 'success')
    .catch(() => {
      return false;
    });
};

const fetchTypeBasedOrderBook = (type) => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
  };

  return wrappedFetch(
    getRequestUri(
      'orderBook',
      `type=${type}&client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then((result) => result?.data?.orders);
};

const fetchOrderBook = async () => {
  const completedOrderBook = await fetchTypeBasedOrderBook('completed');
  const pendingOrderBook = await fetchTypeBasedOrderBook('pending');

  return {
    orderBookDetails: _.compact(
      _.orderBy(
        await parseOrderDetails(
          [...completedOrderBook, ...pendingOrderBook],
          'order'
        ),
        ['time'],
        ['desc']
      )
    ),
    headers: orderTableHeaders,
  };
};

const fetchTradeBook = () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
  };

  return wrappedFetch(
    getRequestUri(
      'tradeBook',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result?.data?.trades, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = async () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
  };

  return wrappedFetch(
    getRequestUri(
      'positionBook',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result?.data, 'position'),
            ['extraDetails.isOpenPosition', 'extraDetails.symbol'],
            ['desc', 'asc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
  };

  return wrappedFetch(
    getRequestUri(
      'holdings',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      return {
        holdingBookDetails: _.compact(
          await parseOrderDetails(result?.data?.holdings, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig, brokerClientId);

      const parameters = {
        method: 'POST',
        headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
        body: JSON.stringify(orderDetails),
      };

      return wrappedFetch(
        getRequestUri(
          'placeOrder',
          `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
        ),
        parameters
      )
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ data, status }) =>
      status === 'success' ? data?.oms_order_id : null
    )
    .filter((value) => value !== null)
    .value();
};

const cancelPlaceOrder = async (orderConfig) => {
  const { orderNo } = orderConfig;
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;
  const orderDetails = parseCancelOrder(orderConfig, brokerClientId);

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
    body: JSON.stringify(orderDetails),
    redirect: 'follow',
  };

  return wrappedFetch(
    getRequestUri(
      'cancelPlaceOrder',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}&execution_type=REGULAR`,
      orderNo
    ),
    parameters
  ).then((response) => response.json());
};

const fetchMarginCalculator = async () => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
    redirect: 'follow',
  };

  const formattedResponse = await wrappedFetch(
    getRequestUri(
      'marginCalculator',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then(({ data }) => data?.values)
    .catch((e) => [e.message]);

  const totalMarginRequired = 0;
  const totalMarginAvailable = _.parseInt(formattedResponse?.[0]?.[1]) || 0;

  return { totalMarginRequired, totalMarginAvailable };
};

const fetchMarginAvailable = async () => await fetchMarginCalculator();

const modifyPlaceOrder = async (placeOrderConfigs) => {
  const { brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs().brokerConfigs;
  const { HDFCSKY_APP_ID } = getDynamicAppConfigs().envs;
  const orderConfig = placeOrderConfigs?.[0];
  const orderDetails = await parseModifyOrder(orderConfig, brokerClientId);

  const parameters = {
    method: 'POST',
    headers: { Authorization: `Bearer ${brokerClientAccessToken}` },
    body: JSON.stringify(orderDetails),
  };
  return wrappedFetch(
    getRequestUri(
      'modifyOrder',
      `client_id=${brokerClientId}&api_key=${HDFCSKY_APP_ID}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then(({ status, data }) =>
      status === 'success' ? [data?.oms_order_id] : null
    );
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
  fetchMarginCalculator,
  modifyPlaceOrder,
  fetchMarginAvailable,
};
