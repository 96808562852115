import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import useUserModeColors from 'globals/hooks/useUserModeColors';
import withResponsive from 'globals/components/withResponsive';

const MobileAppLayout = lazy(() => import('./mobile'));
const WebAppLayout = lazy(() => import('./web'));

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

const ResponsiveComponent = withResponsive(MobileAppLayout, WebAppLayout);

const AppLayout = (props) => {
  useUserModeColors();

  return (
    <ResponsiveComponent {...props} />
  );
};

AppLayout.propTypes = propTypes;
AppLayout.defaultProps = defaultProps;

export default AppLayout;
