import _ from 'lodash';
import EventEmitter from 'events';
import { TICKER_EVENTS } from '../constants/habitual-configs';

class GenericTicker extends EventEmitter {
  constructor() {
    super();

    this.clients = {};
    this.state = {};
    this.additionalData = {};
    this.instruments = [];
    this.userDetails = null;
    this.socket = null;
    this.debugLog = _.noop;
    this.socketConnectPromise = null;
  }

  async start(userDetails, brokerTicker) {
    if (this.socket) {
      this.debugLog('socket connected already');
      return;
    }

    if (!userDetails) {
      this.debugLog('Invalid Userdetails');
      return;
    }

    this.userDetails = userDetails;
    this.brokerTicker = brokerTicker;
    await this.brokerTicker.init(this);
    this.socketConnectPromise = this.brokerTicker.start();
    return this.socketConnectPromise;
  }

  async onSocketConnect(socket) {
    this.socket = socket;
  }

  async subscribe(clientId, instruments) {
    if (this.clients[clientId] && _.some(this.clients[clientId].instruments, instruments)) {
      this.debugLog('[ALT] already subscribed. Duplicate request.');
      return;
    }

    this.clients[clientId] = {
      clientId,
      instruments: _.chain(instruments)
        .thru((result = []) => [...result, ..._.get(this.clientId, `${clientId}.instruments`, [])])
        .uniq()
        .value(),
    };

    if (!this.socket) {
      this.debugLog('[ALT] Socket not yet connected or disconnected');
      return;
    }

    const newTradingSymbols = _.difference(instruments, this.instruments);

    if (_.isEmpty(newTradingSymbols)) {
      this.debugLog('[LT] TradingSymbol already subscribed.');
      return new Promise((resolve) => {
        resolve([this.state]);
      });
    }

    this.instruments = this.instruments.concat(newTradingSymbols);

    return this.brokerTicker.subscribe(newTradingSymbols);
  }

  initiate = () => {
    return this.brokerTicker.subscribe(this.instruments);
  };

  halt = () => {
    return this.brokerTicker.unsubscribe(this.instruments);
  };

  unsubscribeUnusedTradingSymbols = (clientId) => {
    if (this.clients[clientId]) {
      this.debugLog('[ALT] unsubscribing ...');
    } else {
      this.debugLog('[ALT] already unsubscribed. Duplicate request.');
      return;
    }

    delete this.clients[clientId];
    if (!this.socket) {
      this.debugLog('[ALT] unsubscribe socket not connected or disconnected');
      return;
    }

    const newTradingSymbols = _.chain(this.clients)
      .flatMap(({ instruments }) => instruments)
      .uniq()
      .value();

    const staleTradingSymbols = _.difference(this.instruments, newTradingSymbols);
    this.debugLog('[ALT] STALEINSTRUMENTS: ', staleTradingSymbols);
    this.instruments = this.instruments.filter(
      (instrument) => !staleTradingSymbols.includes(instrument)
    );

    if (_.isEmpty(staleTradingSymbols)) {
      this.debugLog('[ALT] other clients are there not unsubscribing.');
      return;
    }

    this.brokerTicker.unsubscribe(staleTradingSymbols);
  };

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }  

  unsubscribe(clientId) {
    return this.delay(2000).then(() => this.unsubscribeUnusedTradingSymbols(clientId));
  }

  silentDestroy() {
    if (this?.brokerTicker?.destroy) {
      this.brokerTicker.destroy();
      this.debugLog('Socket disconnected');
      this.socket = null;
    }
  }

  onState(emitTicks) {
    this.emit(TICKER_EVENTS.TICKS, emitTicks);
  }

  onTick(emitTicks) {
    this.emit(TICKER_EVENTS.TICKS, emitTicks);
  }
}

export const genericTicker = new GenericTicker();