import { instrumentLotSizeAndStrikeIntervalConfigs } from 'habitual-analytics/constants/habitual-configs';
import { getStrikeIndexAndStrikeLabel } from 'habitual-analytics/formProvider/BasketFormProvider/processor';
import { getValidatedInstrumentAndExpiryType } from 'habitual-analytics/utils/instrument';
import { updateGlobalConfigs } from 'redux_store/action/index';

export const onNavigateToCombinedPremium = (selectedInstrument, dispatch, navigate) => {
  const { expiryDate } = getValidatedInstrumentAndExpiryType(selectedInstrument);

  dispatch(
    updateGlobalConfigs({
      headerConfigs: {
        instrument: selectedInstrument,
        expiryDate,
      },
      commonConfigs: {
        isNavigateToCombinedPremium: true,
      }
    }),
  );

  navigate('/basket');
};

export const onNavigateOptionChain = (selectedInstrument, dispatch, navigate) => {
  const { expiryDate } = getValidatedInstrumentAndExpiryType(selectedInstrument);

  dispatch(
    updateGlobalConfigs({
      headerConfigs: {
        instrument: selectedInstrument,
        expiryDate,
        strikePrices: []
      },
      commonConfigs: {
        isNavigateToOptionChain: true,
      }
    }),
  );

  navigate('/instruments');
};

export const onNavigateToPayoutChart = (orderConfigs, atm, dispatch) => {
  const basketLegs = _.map(orderConfigs, ({ tradingSymbolObj, transactionType }, index) => {
    const instrumentConfig = instrumentLotSizeAndStrikeIntervalConfigs(
      tradingSymbolObj?.symbol,
      tradingSymbolObj?.expiryDate,
    );
    const { lot_size: instrumentLotSize, strike_interval: strikeInterval } = instrumentConfig;

    return {
      optionType: tradingSymbolObj?.optionType,
      transactionType,
      strikePrice: tradingSymbolObj?.strikePrice,
      lot: tradingSymbolObj?.isEquity() ? 1 : _.floor(Math.abs(orderConfigs[index]?.qty / instrumentLotSize)),
      legExpiry: tradingSymbolObj?.expiryDate,
      strikeIndex: getStrikeIndexAndStrikeLabel(atm, tradingSymbolObj?.strikePrice, strikeInterval)
        .strikeIndex,
      tradingSymbolObj,
      price: orderConfigs?.[index]?.price,
      isActivePosition: orderConfigs?.[index]?.isActivePosition,
    };
  });

  dispatch(
    updateGlobalConfigs({
      basketConfigs: {
        legs: basketLegs,
      },
      commonConfigs: {
        isNavigateToPayoutChart: true,
      },
    }),
  );
};

export const isAllSameOptionSymbol = (orderConfigs) => {
  return _.every(
    orderConfigs,
    ({ tradingSymbolObj }) => tradingSymbolObj.symbol === orderConfigs[0].tradingSymbolObj.symbol
      && tradingSymbolObj.getInstrumentType() === 'OPT',
  );
};

export const setDocumentProperty = (property, value) => {
  document.documentElement.style.setProperty(property, value);
};

export const getDocumentProperty = (property) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
};
