import _ from 'lodash';
import {
  thursdayExpiryDates,
  thursdayExpiryDatesMonthly,
  tuesdayExpiryDates,
  tuesdayExpiryDatesMonthly,
  bankNiftyExpiryDates,
  bankNiftyExpiryDatesMonthly,
  midcpNiftyExpiryDates,
  midcpNiftyExpiryDatesMonthly,
  bseFridayExpiryDatesMonthly,
  bseMondayExpiryDatesMonthly,
  bseFridayExpiryDates,
  bseMondayExpiryDates
} from 'zeequant-constants';
import { DATE_FORMAT } from './dateFormats';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';

const getInstrumentExpiryDates = (instrument, isMonthly) => {
  switch (instrument) {
    case 'BANKNIFTY':
      return isMonthly ? bankNiftyExpiryDatesMonthly : bankNiftyExpiryDates;
    case 'FINNIFTY':
      return isMonthly ? tuesdayExpiryDatesMonthly : tuesdayExpiryDates;
    case 'MIDCPNIFTY':
      return isMonthly ? midcpNiftyExpiryDatesMonthly : midcpNiftyExpiryDates;
    case 'BANKEX':
      return isMonthly ? bseMondayExpiryDatesMonthly : bseMondayExpiryDates;
    case 'SENSEX':
      return isMonthly ? bseFridayExpiryDatesMonthly : bseFridayExpiryDates;
    default:
      return isMonthly ? thursdayExpiryDatesMonthly : thursdayExpiryDates;
  }
};

export const getNextMonthExpiryDates = (instrument = '', isMonthly = false) => {
  const nextMonth = getCurrentDate().month() + 2 === 13 ? 1 : getCurrentDate().month() + 2;
  const year = getCurrentDate().month() + 2 === 13 ? getCurrentDate().year() + 1 : getCurrentDate().year();

  return _.filter(getInstrumentExpiryDates(instrument, isMonthly), (date) => {
    return (
      getParsedDateObj(date).year() === year
      && getParsedDateObj(date).month() + 1 === nextMonth
    );
  });
};

export const getCurrentMonthExpiryDates = (instrument = '', isMonthly = false) => {
  return _.filter(
    getInstrumentExpiryDates(instrument, isMonthly),
    (date) => getParsedDateObj(date).year() === getCurrentDate().year()
      && getParsedDateObj(date).month() + 1 === getCurrentDate().month() + 1
  );
};

export const getCurrentYearExpiryDates = (instrument) => {
  return _.filter(
    getInstrumentExpiryDates(instrument, false),
    (date) => getParsedDateObj(date).year() === getCurrentDate().year()
  );
};

export const getCurrentWeeklyExpiryDate = (instrument) => {
  const monthlyExpiryDates = getCurrentMonthExpiryDates(instrument);
  const expiryDates = getInstrumentExpiryDates(instrument, false);
  const currentWeeklyExpiryDate = _.find(
    monthlyExpiryDates,
    (monthlyExpiryDate) => monthlyExpiryDate >= getCurrentDate().format(DATE_FORMAT)
  );
  
  // 1. Will give current Weekly Expiry
  // 2. If the Current Week is Expired.. like 27 is current Date.. 26 is last expiry in current month..
  // so have to next month 1st expiry.. it will be in else return moment

  return (
    currentWeeklyExpiryDate || expiryDates?.[expiryDates.indexOf(_.last(monthlyExpiryDates)) + 1]
  );
};

export const getWeeklyExpiryDates = (instrument) => {
  return getInstrumentExpiryDates(instrument, false);
};

export const getMonthlyExpiryDates = (instrument) => {
  return getInstrumentExpiryDates(instrument, true);
};

export const getUpComingExpiryDates = (expiryType = 'M', instrument) => {
  return _.filter(
    expiryType === 'M' ? getMonthlyExpiryDates(instrument) : getWeeklyExpiryDates(instrument),
    (expiryDate) => expiryDate >= getCurrentDate().format(DATE_FORMAT)
  );
};

export const getExpiredExpiryDates = (expiryType = 'M', instrument) => {
  return _.filter(
    expiryType === 'M' ? getMonthlyExpiryDates(instrument) : getWeeklyExpiryDates(instrument),
    (expiryDate) => expiryDate < getCurrentDate().format(DATE_FORMAT)
  );
};

export const getMonthBasedExpiryDates = (month = '01', expiryDates = []) => {
  const currentYear = getCurrentDate().year();
  return _.filter(expiryDates, (expiryDate) => {
    if (_.includes(expiryDate, `${currentYear}-${month}`)) {
      return expiryDate;
    }
    return false;
  });
};
